.carousel-container {
  // width: 80%;
  // height: 100%;
  margin: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--lx-gap);
  // margin-top: 40px;

  .carousel {
    aspect-ratio: 16/9;
    width: 100%;
    position: relative;
    overflow: hidden;

    .item {
      opacity: 0;
      width: 100%;
      height: 100%;
      display: none;
      transition: opacity 0.5s ease-in-out;

      img.album-image-carousel {
        width: 60%;
        height: 60%;
        object-fit: cover; /* Ensures images cover the container */
        object-position: center; /* Centers the image within the container */
      }

      &.active {
        opacity: 1;
        display: block;
      }
    }
  }

  .btn {
    display: flex; /* Enable flexbox layout */
    align-items: center; /* Center text vertically */
    justify-content: center; /* Center text horizontally */
    padding: 1em 2em;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    outline: none;
    border: none;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 900;
    color: #10100e;
    background-color: rgba(255, 255, 255, 0.8); /* White with 80% opacity */
    transition: transform 0.2s ease-in-out, background-color 0.2s ease;
    z-index: 1000; /* Ensure buttons are on top */

    &:active,
    &:focus {
      transform: translateY(-50%) scale(0.9);
    }

    &:hover {
      transform: translateY(-50%) scale(0.96);
    }
  }

  .prev {
    left: 5%; /* Adjusted for better positioning */
  }

  .next {
    right: 5%; /* Adjusted for better positioning */
  }

  .dots {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    .dot {
      cursor: pointer;
      height: 10px;
      width: 10px;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.345);
      transition: background-color 0.2s ease;
      margin-top: 10px;

      &.active,
      &:hover {
        background-color: #ffffe6;
      }
    }
  }
}

@media (max-width: 800px) {
  .carousel-container {
    width: 100%; // Adjust width if needed
  }

  .carousel {
    aspect-ratio: 4/3; // Adjust aspect ratio if needed

    .item {
      img.album-image-carousel {
        height: auto;
      }
    }
  }

  .btn {
    padding: 0.5em 1em; // Adjust button size if needed
    font-size: 10px;
  }

  .prev {
    left: 3%; // Adjust for smaller screens
  }

  .next {
    right: 3%; // Adjust for smaller screens
  }
}

@media (max-width: 480px) {
  .carousel-container {
    width: 100%; // Full width on very small screens
    margin: 0;
    padding: 0;
  }

  .carousel {
    aspect-ratio: 16/9; // Adjust aspect ratio if needed
  }

  .btn {
    padding: 0.5em; // Further adjust button size if needed
    font-size: 12px;
  }

  .prev,
  .next {
    width: 20px; 
    height: 20px;
  }

  .album-image-carousel {
    height: 400px;
  }
}
@media (max-width: 335px){
  .album-image{
    padding-left: 5%;
    padding-right: 5%; 
    border-radius: 10px;
  }
}
