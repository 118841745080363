/************ Custom Font ************/
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700;900&display=swap');

.font-weight-1000 {
  font-weight: 900; /* Montserrat doesn't have 1000, so use 900 for extra-bold */
}

.font-weight-900 {
  font-weight: 900;
}

.font-weight-700 {
  font-weight: 700;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-400 {
  font-weight: 400;
}

/* Header */
.header {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: white;
  color: rgba(0, 0, 0, 0.92);
  padding: 1px;
  text-align: center;
  font-weight: bold;
  z-index: 1000;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.header-container {
  width: 100%;
  position: relative; /* Ensure relative positioning for absolute children */
  z-index: 100; /* Adjust as needed */
}

/* Hidden class to animate out */
.header.hidden {
  transform: translateY(-100%);
  opacity: 0;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center; /* Center contents horizontally */
  height: 80px;
  position: relative;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 0;
  width: auto; /* Adjusted for responsiveness */
  height: auto;
  text-align: center;
  position: absolute; /* Positioned absolutely for centering */
  left: 50%;
  transform: translateX(-50%); /* Centering horizontally */
  margin-top: 15px;
}

.logo a {
  display: block;
  width: 100%;
  height: 100%;
  transition: transform 0.3s ease;
}

.logo img {
  max-height: 110%;
  width: 120px;
  height: 100px;
  border-radius: 10px;
  max-width: none;
}

nav {
  flex: 1;
  font-weight: 700;
  font-size: 18px;
}

nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

nav.left-nav ul {
  margin-left: 20px;
  justify-content: flex-start;
}

nav.right-nav ul {
  margin-right: 20px;
  justify-content: flex-end;
}

nav.left-nav ul,
nav.right-nav ul {
  margin: 0; /* Reset margin for responsive layout */
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center; /* Center contents on smaller screens */
}

nav ul li {
  margin: 0 1rem;
}

nav ul li a {
  color: rgb(0, 0, 0);
  text-decoration: none;
  transition: color 0.3s ease, transform 0.3s ease;
}

nav ul li a:hover {
  color: rgba(255, 255, 255, 0.675);
  transform: scale(1.5);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.right-nav ul li a:focus,
.right-nav ul li a:active {
  transform: none;
  outline: none;
  filter: none;
}

.right-nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}

.right-nav ul li {
  margin-left: 10px;
}

.right-nav ul li a {
  display: block;
  text-decoration: none;
  position: relative;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.right-nav ul li a img {
  max-width: 30px;
  height: auto;
  vertical-align: middle;
  transition: transform 0.1s, filter 0.1s;
}

.right-nav ul li a[href*="facebook"]:hover img,
.right-nav ul li a[href*="facebook"]:focus img,
.right-nav ul li a[href*="facebook"]:active img {
  filter: brightness(0) saturate(100%) invert(43%) sepia(100%) saturate(748%) hue-rotate(174deg) brightness(89%) contrast(101%);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.right-nav ul li a[href*="instagram"]:hover img,
.right-nav ul li a[href*="instagram"]:focus img,
.right-nav ul li a[href*="instagram"]:active img {
  filter: brightness(0) saturate(100%) invert(71%) sepia(23%) saturate(5631%) hue-rotate(320deg) brightness(93%) contrast(102%);
}

.right-nav ul li a {
  outline: none;
}

.nav-toggle {
  display: none;
  font-size: 2.5rem;
  cursor: pointer;
  position: absolute;
  left: 15%;
  margin-top: 60px;
  /* margin-left: 100px; */
  transform: translateY(-50%); /* Center vertically */
  z-index: 2000;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

/* Responsive styles */
@media (max-width: 1060px) {
  .nav-toggle {
    display: block;
  }

  nav.left-nav,
  nav.right-nav {
    display: none;
  }

  nav.left-nav.show-menu,
  nav.right-nav.show-menu {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.95);
    justify-content: center;
    align-items: center;
    transform: translateY(0);
    transition: transform 0.3s ease;
    z-index: 1000;
  }

  nav.left-nav ul,
  nav.right-nav ul {
    flex-direction: column;
    margin: 0;
    padding: 0;
  }

  nav.left-nav ul li,
  nav.right-nav ul li {
    margin: 1rem 0;
  }

  nav.right-nav.show-menu {
    display: flex;
    flex-direction: column;
  }
}

@media (min-width: 762px) {
  nav.left-nav ul {
    margin-left: 20px;
    justify-content: center;
  }

  nav.right-nav ul {
    margin-right: 20px;
    justify-content: center;
  }
}

@media (max-width: 350px){
  .container{
    width: 100%;
  }
  .header {
    max-width: 350px !important;
  }
}

@media (max-width: 335px){
  .header{
    width: 100%;
  }
  .header {
    max-width: 335px !important;
  }
}
