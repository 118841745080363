/************ Custom Font ************/
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700;900&display=swap');

.font-weight-1000 {
  font-weight: 900; /* Montserrat doesn't have 1000, so use 900 for extra-bold */
}

.font-weight-900 {
  font-weight: 900;
}

.font-weight-700 {
  font-weight: 700;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-400 {
  font-weight: 400;
}

@function multiple-box-shadow($n) {
  $value: '#{random(2000) - 1000}px #{random(2000) - 1000}px #FFF';
  @for $i from 2 through $n {
    $value: '#{$value}, #{random(2000) - 1000}px #{random(2000) - 1000}px #FFF';
  }
  @return unquote($value);
}

$shadows-small: multiple-box-shadow(700);
$shadows-medium: multiple-box-shadow(200);
$shadows-big: multiple-box-shadow(100);

.hero {
  position: relative; // Ensure relative positioning for absolute children
  height: 750px; // Full viewport height for hero section
  width: 1400px;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  
  #stars, #stars2, #stars3 {
    position: absolute;
    background: transparent;
    animation: animStar linear infinite;
    z-index: 1;
  }

  #stars {
    width: 1px;
    height: 1px;
    box-shadow: $shadows-small;
    animation-duration: 50s;
  }

  #stars2 {
    width: 2px;
    height: 2px;
    box-shadow: $shadows-medium;
    animation-duration: 100s;
  }

  #stars3 {
    width: 3px;
    height: 3px;
    box-shadow: $shadows-big;
    animation-duration: 150s;
  }

  
  #title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: black;
    text-align: center;
    // font-family: TCC, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    font-weight:500;
    font-size: 40px;
    letter-spacing: 10px;
    margin-top: 40px;
    padding-left: 10px;

    span {
      background: linear-gradient(rgb(255, 255, 255), #ffffff);
      background: -webkit-linear-gradient(rgb(255, 255, 255), #fdfdfd);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      color: transparent;
    }
  }

  @keyframes animStar {
    from {
      transform: translateY(0px);
    }
    to {
      transform: translateY(-2000px);
    }
  }
}

.image-container {
  position: relative; // Adjust to ensure centering
  width: 100%; // Full width of hero section
  // height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-top: 100px;
  z-index: 0; // Ensure container is below the title
}

.box {
  flex: 1;
  width: 100%;
  height: 100%;
  justify-content: center;
  // max-width: 450px;
  // max-height: 670px;
  border: 2px solid #fff;
  // border-radius: 10px;
  // overflow: hidden;

  .center-image {
    width: 100%;
    height: 100%;
    display: block;
  }
}

.center-image {
    width: 100%;
    height: 100vh;
    object-fit: cover; // Ensure the image covers the container without distortion
    object-position: center;
    z-index: 0;
}

@media screen and (max-width: 1400px) {
  .hero {
    width: 100%;
    max-width: 100%;
  }
  #title {
    font-size: 30px;
    width: 100%;
  }
}
@media screen and (max-width: 1200px) {
  .hero {
    height: auto;
    max-height: none;
  }
  .box {
    width: 100%;
    // max-width: 250px; // Adjust max-width as needed for smaller screens
  }

  #title {
    font-size: 30px;
    width: 100%;
  }
}

@media screen and (max-width: 800px) {
  .hero {
    height: 500px;
    max-height: none;
    margin-top: 70px;
    justify-content: center;
    align-items: center;
  }
  .box {
    width: 100%;
    max-width: 250px; // Adjust max-width as needed for smaller screens
  }

  #title {
    font-size: 30px;
    width: 100%;
    top: 40%;
  }
}